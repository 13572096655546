/**
 * 接口统一导出
 */
import baseAPI from './modules/base'
import homeAPI from './modules/home'
import aboutAPI from './modules/about'
import newsAPI from './modules/news'
import joinAPI from './modules/join'
import algorithmAPI from './modules/algorithms'
import solutionAPI from './modules/solutions'
import mdAPI from './modules/markdown'

const api = {
  baseAPI,
  homeAPI,
  aboutAPI,
  newsAPI,
  joinAPI,
  algorithmAPI,
  solutionAPI,
  mdAPI
}

export default api
