// 函数防抖的实现
export function debounce (fn: Function, wait: number) {
  let timer:number|null = null
  return function (...args:any) {
    // 如果此时存在定时器的话，则取消之前的定时器重新记时
    if (timer) {
      clearTimeout(timer)
      timer = null
    }
    // 设置定时器，使事件间隔指定事件后执行
    timer = window.setTimeout(() => {
      fn.apply(null, args)
    }, wait)
  }
}

// 函数节流的实现;
export function throttle (fn: Function, delay: number) {
  let curTime = Date.now()
  return function (...args: any) {
    const nowTime = Date.now()
    // 如果两次时间间隔超过了指定时间，则执行函数。
    if (nowTime - curTime >= delay) {
      curTime = Date.now()
      return fn.apply(null, args)
    }
  }
}
