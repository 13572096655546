/**
 * 加入我们请求
 */
import request from '@/utils/request'
import { Recruitment } from '../types/join'

const base = 'join'

const joinAPI = {
  getJoinList: () => {
    return request<Recruitment[]>(base + '/list')
  },
  getJoinDetail: (id:number) => {
    return request<Recruitment>(base + '/detail/' + id)
  }
}

export default joinAPI
