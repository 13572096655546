<template>
  <ti-content-row
    class="z-10 w-full transition duration-300 select-none"
    content-class="h-14 items-center"
    :class="[
      hover&&onTop?'bg-transparent text-white hover:bg-white hover:text-grey-3': 'bg-white text-grey-3 border-b',
      position
    ]"
    @mouseover="hovering = true"
    @mouseleave="hovering = false"
  >
    <TopBrand :logo="!(hover&&onTop)||hovering?'logo':'logo-white'" />
    <a
      class="relative px-8 cursor-pointer hover:text-primary text-sm  flex-shrink-0"
      style="line-height: 3.5rem;"
      v-for="(menuItem,index) in menu"
      :key="menuItem.title"
      @click="()=>handledPathPush(menuItem.path)"
      @mouseover="hovingIndex = index"
      @mouseleave="hovingIndex = -1"
    >
      {{ menuItem.title }}
      <div
        v-if="menuItem.children"
        v-show="hovingIndex === index"
        class="absolute -left-3 cursor-default"
      >
        <div class="bg-white flex text-grey-3 py-8 border">
          <TopMenuItemList
            class="mx-12"
            v-for="(item,itemIndex) in menuItem.children"
            :key="itemIndex"
            :menudata="item"
          />
        </div>
      </div>
    </a>
  </ti-content-row>
</template>

<script setup lang="ts">
import { onMounted, onUnmounted, ref, watch } from 'vue'
import api from '@/api/index'
import { MenuItem } from '@/api/types/menu'
import TopBrand from './top-brand.vue'
import TopMenuItemList from './top-menu-item-List.vue'
import { handledPathPush } from '@/utils/router'
import { useRoute } from 'vue-router'
import { debounce } from '@/utils/function'
import bus from '@/utils/event-bus'
const position = ref<string>('fixed')
bus.set('menu/position', position)
onUnmounted(() => {
  bus.delete('menu/position')
})

// 判断菜单是否为隐藏模式
const hover = ref<boolean>(false)
const hovering = ref<boolean>(false)
const hoverPaths = ['/', '/about']
const route = useRoute()
hover.value = hoverPaths.indexOf(route.fullPath) >= 0
watch(() => route.fullPath, () => {
  hover.value = hoverPaths.indexOf(route.fullPath) >= 0
})
// 菜单数据获取
const menu = ref<MenuItem[]| undefined>([])
onMounted(async () => {
  menu.value = await api.baseAPI.getMenu()
})
// 控制子菜单展示
const hovingIndex = ref<number>(-1)
// 处理菜单是否显示
const onTop = ref<boolean>(true)
let scrolling:boolean = false
const handleScrolling = debounce(() => {
  const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
  onTop.value = scrollTop === 0
  scrolling = false
}, 100)
const scrollcb = () => {
  if (hover.value) {
    if (!scrolling) {
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      onTop.value = scrollTop === 0
      scrolling = true
    }
    handleScrolling()
  }
}
document.addEventListener('scroll', scrollcb)
onUnmounted(() => {
  document.removeEventListener('scroll', scrollcb)
})
</script>
