/**
 * 新闻动态请求
 */

import request from '@/utils/request'
import { New } from '../types/new'

const base = 'news'

const newsAPI = {
  getNewsList: () => {
    return request<New[]>(base + '/list')
  }
}

export default newsAPI
