<template>
  <div class="w-full h-screen flex items-center justify-center">
    <div class="flex items-center">
      <div class="text-5xl">
        404
      </div>
      <div class="border-l h-28 mx-10" />
      <div class="text-center">
        <div class="mb-5 text-grey-6">
          即将发布，敬请期待！(<span id="timesecond">{{ timeseconds }}</span>s)后返回首页
        </div>
        <ti-button
          type="line-grey"
          @click="()=>handledPathPush('/')"
        >
          立即返回
        </ti-button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { handledPathPush } from '@/utils/router'
import { ref } from '@vue/reactivity'

const timeseconds = ref(3)

const timer = setInterval(() => {
  timeseconds.value--
  if (timeseconds.value === 0) {
    handledPathPush('/')
    clearInterval(timer)
  }
}, 1000)
</script>
