import { createApp } from 'vue'
import App from './App.vue'
// 引入tailwindcss
import './index.css'
// 引入vue-router
import router from './router'
import lazyPlugin from 'vue3-lazy'
import lazyBgPlugin from './directives/lazy-background-image'

createApp(App)
  .use(router)
  .use(lazyPlugin, { })
  .use(lazyBgPlugin)
  .mount('#app')
