/**
 * 首页请求
 */

import request from '@/utils/request'
import { Carousel } from '../types/carousel'
import { Production } from '../types/production'
import { Customer } from '../types/customer'

const base = 'home'

const homeAPI = {
  getCarousel: () => {
    return request<Carousel[]>(base + '/carousel')
  },
  getProductions: () => {
    return request<Production[]>(base + '/productions')
  },
  getCustomers: () => {
    return request<Customer[]>(base + '/customers')
  }
}

export default homeAPI
