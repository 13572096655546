/**
 * 关于我们请求
 */

import request from '@/utils/request'
import { AboutData, TimeLineItem } from '../types/about'

const base = 'about'

const aboutAPI = {
  getAboutData: () => {
    return request<AboutData>(base + '/data')
  },
  getTimeLine: () => {
    return request<TimeLineItem[]>(base + '/timeline')
  }
}

export default aboutAPI
