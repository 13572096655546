import { RouteRecordRaw, RouterView } from 'vue-router'

const routes: RouteRecordRaw =
{
  path: '',
  component: RouterView,
  children: [
    {
      path: '',
      name: 'home',
      component: () => import('@/views/home/home-index.vue')
    }
  ]
}

export default routes
