/**
 * 基础请求
 */

import request from '@/utils/request'
import { MenuItem } from '../types/menu'

const base = 'base'

const baseAPI = {
  getMenu: () => {
    return request<MenuItem[]>(base + '/menu')
  }
}

export default baseAPI
