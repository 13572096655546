import NotFoundLayout from '@/layout/not-found-layout.vue'
import { RouteRecordRaw, RouterView } from 'vue-router'

const routes: RouteRecordRaw = {
  path: 'algorithms',
  component: RouterView,
  meta: {
    title: 'AI算法'
  },
  children: [
    {
      path: '',
      name: 'algorithms',
      component: NotFoundLayout
    },
    {
      path: 'detail/:id',
      name: 'algorithms-detail',
      component: () => import('@/views/algorithms/algorithms-index.vue'),
      meta: {
        title: 'AI算法详情'
      }
    }
  ]
}

export default routes
