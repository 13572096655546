import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import AppLayout from '@/layout/app-layout.vue'
import NotFoundLayout from '@/layout/not-found-layout.vue'
import homeRoutes from './modules/home'
import algorithmsRoutes from './modules/algorithms'
import solutionsRoutes from './modules/solutions'
import aboutRoutes from './modules/about'
import newsRoutes from './modules/news'
import joinRoutes from './modules/join'
import devicesRoutes from './modules/devices'
import IframeLayout from '@/layout/iframe-layout.vue'
import DeclarationLayout from '@/layout/declaration-layout.vue'
import { changeDocumentTitle, pushAnimation } from '@/utils/router'

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    component: AppLayout,
    children: [
      homeRoutes,
      algorithmsRoutes,
      solutionsRoutes,
      aboutRoutes,
      newsRoutes,
      joinRoutes,
      devicesRoutes,
      {
        path: 'declaration/:doc',
        name: 'declaration',
        component: DeclarationLayout
      },
      {
        path: 'production/:path',
        name: 'production',
        component: IframeLayout
      }
    ]
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    component: NotFoundLayout
  }

]

const router = createRouter({
  history: createWebHashHistory(), // 路由模式
  routes: routes,
  scrollBehavior (to, from, savedPosition) {
    // 始终滚动到顶部
    return { top: 0 }
  }
})

router.afterEach((to, from, failure) => {
  if (to.fullPath !== from.fullPath) {
    changeDocumentTitle(to?.meta?.title as (string|undefined));
    (to?.meta?.animation !== false) && pushAnimation()
  }
})
export default router
