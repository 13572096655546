import NotFoundLayout from '@/layout/not-found-layout.vue'
import { RouteRecordRaw, RouterView } from 'vue-router'

const routes: RouteRecordRaw = {
  path: 'solutions',
  component: RouterView,
  meta: {
    title: '解决方案'
  },
  children: [
    {
      path: '',
      name: 'solutions',
      component: NotFoundLayout
    },
    {
      path: 'detail/:id',
      name: 'solutions-detail',
      component: () => import('@/views/solutions/solutions-index.vue'),
      meta: {
        title: '解决方案详情'
      }
    }
  ]
}

export default routes
