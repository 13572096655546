<template>
  <div
    class="hover:text-white hover:bg-primary transition cursor-pointer px-10 py-3 inline-block"
    :class="getButtonType()"
  >
    <slot />
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  type: {
    type: String,
    default: 'default'
  }
})

const getButtonType = ():string => {
  switch (props.type) {
    case 'line-light':
      return 'text-white bg-transparent border border-white hover:border-primary'
    case 'line-grey':
      return 'text-grey-3 bg-transparent border hover:border-primary'
    default:
      return 'text-grey-6 bg-white'
  }
}
</script>
