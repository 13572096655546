import { throttle } from '@/utils/function'
import { App } from 'vue'

class BackgroundImageManager {
    el
    url
    throttleLazyHandler
    bufferDistance
    events = ['scroll', 'wheel', 'mousewheel', 'resize', 'animationend', 'transitionend', 'touchmove', 'transitioncancel']
    constructor (el:HTMLElement, url: string, bufferDistance = 200) {
      this.el = el
      this.url = url
      this.bufferDistance = bufferDistance
      this.updated('初始化触发')
      this.throttleLazyHandler = throttle(this.updated.bind(this, '滚动触发'), 300)
    }

    get isInView (): boolean {
      const rect = this.el.getBoundingClientRect()
      return rect.top < (window.innerHeight + this.bufferDistance) && rect.left < (window.innerWidth + this.bufferDistance)
    }

    resetUrl (url:string) {
      if (url !== this.url) {
        this.url = url
        this.updated('更新触发')
      }
    }

    updated (str:string) {
      // console.log(str + '-update触发', this.el, this.url)
      if (this.isInView) {
        if (this.url) {
          // console.log(str + '-url设置', this.el, this.url)
          this.el.style.backgroundImage = `url(${this.url})`
          this.el.classList.add('bg-cover', 'bg-center', 'bg-no-repeat')
        }
        Promise.resolve().then(() => {
          // console.log(str + '-监听销毁', this.el, this.url)
          this.removeListener()
        })
      }
    }

    addListener (): void {
      this.events.forEach((event) => {
        window.addEventListener(event, this.throttleLazyHandler, {
          passive: true,
          capture: false
        })
      })
    }

    removeListener (): void {
      this.events.forEach((event) => {
        window.removeEventListener(event, this.throttleLazyHandler)
      })
    }
}

export default {
  install (app: App) {
    const elMap = new Map<HTMLElement, BackgroundImageManager>()
    app.directive('bg', {
      mounted: (el: HTMLElement, binding, vnode, prevVnode) => {
        const backgroundImageManager = new BackgroundImageManager(el, binding.value)
        elMap.set(el, backgroundImageManager)
        backgroundImageManager.addListener()
      },
      updated (el: HTMLElement, binding, vnode, prevVnode) {
        const backgroundImageManager = elMap.get(el)
        backgroundImageManager?.resetUrl(binding.value)
      },
      unmounted: (el, binding, vnode, prevVnode) => {
        const backgroundImageManager = elMap.get(el)
        backgroundImageManager?.removeListener()
        elMap.delete(el)
      }
    })
  }
}
