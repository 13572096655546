
new Map().set('Accept', 'application/json')

function getData (url: string, responseType:string = 'json') {
  const promise = new Promise(function (resolve, reject) {
    const xhr = new XMLHttpRequest()
    xhr.open('GET', url, true)
    xhr.onreadystatechange = function () {
      if (this.readyState !== 4) return
      if (this.status === 200) {
        resolve(this.response)
      } else {
        reject(new Error(this.statusText))
      }
    }
    xhr.onerror = function () {
      reject(new Error(this.statusText))
    }
    switch (responseType) {
      case 'json':
        xhr.responseType = 'json'
        xhr.setRequestHeader('Accept', 'application/json')
        break
    }
    xhr.send(null)
  })
  return promise
}

// 请求方法
const request = async function<T> (url: string, extraPath:string = '', requestFileType:string = 'json') {
  let data: any
  try {
    const urlArr = url.split('/')
    data = await getData(`data/${extraPath + urlArr.shift()}.${requestFileType}`, requestFileType)
    urlArr.forEach(element => {
      data = data[element]
    })
  } catch (error) {
    console.log(url + ': 请求失败', error)
    return
  }
  return data as T
}

export default request
