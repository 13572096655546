import router from '@/router/index'

const newPageStartWith = ['http', '/production', 'production']

// 判断那些页面进行新页面跳转
export function handledPathPush (path?: string) {
  path && pathPush(path, newPageStartWith.some(item => path.startsWith(item)))
}

// 处理传入路由路径跳转
function pathPush (path: string, newPage?: boolean) {
  if (path) {
    if (path.includes('http')) {
      if (newPage) {
        window.open(path)
      } else {
        window.location.href = path
      }
    } else {
      if (newPage) {
        const routeUrl = router.resolve({
          path: path
        })
        window.open(routeUrl.href, '_blank')
      } else {
        router.push(path)
      }
    }
  }
}

// 处理页面跳转标题切换
export function changeDocumentTitle (title?:string|undefined) {
  document.title = title ?? '钛灵科技 - 人工智能技术与智能硬件产品创新研发企业'
}

// 处理页面跳转渐变
export function pushAnimation () {
  const el = document.getElementById('router-change')
  el?.classList.remove('changing')
  window.requestAnimationFrame(function (time) {
    window.requestAnimationFrame(function (time) {
      el?.classList.add('changing')
    })
  })
}
