import { RouteRecordRaw, RouterView } from 'vue-router'

const routes: RouteRecordRaw = {
  path: 'news',
  component: RouterView,
  meta: {
    title: '新闻动态'
  },
  children: [
    {
      path: '',
      name: 'news',
      component: () => import('@/views/news/news-index.vue')
    },
    {
      path: 'detail/:id',
      name: 'news-detail',
      component: () => import('@/views/news/news-detail.vue'),
      meta: {
        title: '新闻动态详情'
      }
    }
  ]
}

export default routes
