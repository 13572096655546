/**
 * AI算法请求
 */

import request from '@/utils/request'
import { Production, ProductionDetail, ProductionAdvantage } from '../types/production'
import { SolutionScene, SolutionSummary } from '../types/solutions'

const base = 'solutions/'

const solutionAPI = {
  getDetail: (title: string) => {
    return request<ProductionDetail>(title + '/detail', base)
  },
  getSummary: (title: string) => {
    return request<SolutionSummary>(title + '/summary', base)
  },
  getScenes: (title: string) => {
    return request<SolutionScene[]>(title + '/scenes', base)
  },
  getAdvantages: (title: string) => {
    return request<ProductionAdvantage[]>(title + '/advantages', base)
  },
  getRecommendations: (title: string) => {
    return request<Production[]>(title + '/recommendations', base)
  }
}

export default solutionAPI
