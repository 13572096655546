/**
 * markDown文件请求
 */

import request from '@/utils/request'

const base = 'md'

const mdAPI = {
  getJoinMarkDown: (fileName: string) => {
    return request<string>(fileName, base + '/join/', 'md')
  },
  getNewsMarkDown: (fileName: string) => {
    return request<string>(fileName, base + '/news/', 'md')
  },
  getDeclarationMarkDown: (fileName: string) => {
    return request<string>(fileName, base + '/declaration/', 'md')
  }
}

export default mdAPI
