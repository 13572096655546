import { RouteRecordRaw, RouterView } from 'vue-router'

const routes: RouteRecordRaw = {
  path: 'about',
  component: RouterView,
  meta: {
    title: '关于钛灵'
  },
  children: [
    {
      path: '',
      name: 'about',
      component: () => import('@/views/about/about-index.vue')
    }
  ]
}

export default routes
