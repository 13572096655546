<template>
  <ti-content-row class="h-96 bg-grey-f5 cursor-default">
    <div class="flex-1 flex flex-col justify-center">
      <div class="h-60">
        <div class="flex items-center">
          <img
            v-lazy="getAssetsFile('img/logo.webp')"
            class="w-16 h-16 mr-5"
          >
          <div class="text-2xl font-bold text-grey-3">
            深圳钛灵科技有限公司
          </div>
        </div>
        <div class="ml-20 mt-4 text-sm text-grey-9">
          <div class="mb-3">
            深圳市南山区高新南九道9号威新软件科技园2号楼5层北翼NS09
          </div>
          <div>
            安徽省合肥市龙川路中国视界A3#404-405
          </div>
          <!-- <div class="mt-12 flex items-center">
            <div>关注我们</div>
            <img
              v-lazy="getAssetsFile('img/home/sina.webp')"
              class="w-6 ml-6 cursor-pointer"
            >
            <img
              v-lazy="getAssetsFile('img/home/wechat.webp')"
              class="w-6 ml-6 cursor-pointer"
            >
          </div> -->
        </div>
      </div>
    </div>
    <div class="flex flex-col items-center justify-center pr-20">
      <div class="h-60">
        <div class="text-lg font-bold text-grey-3">
          相关链接
        </div>
        <div class="text-sm text-grey-9 leading-8 pt-6">
          <div
            class="cursor-pointer hover:text-primary"
            v-for="item in links"
            :key="item.title"
            @click="()=>handledPathPush(item.path)"
          >
            {{ item.title }}
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-col justify-center">
      <div class="h-60">
        <div class="text-lg font-bold text-grey-3">
          商务合作
        </div>
        <div class="text-3xl font-bold text-white text-center py-4 bg-primary w-72 my-6">
          0551-65851213
        </div>
        <div class="text-sm text-grey-9">
          <div>( 周一至周五 9:00-12:00, 13:00-18:00 )</div>
          <div class="mt-3">
            <a href="mailto:market@gravitylink.com">market@gravitylink.com</a>
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-col justify-center ml-10">
      <div class="h-60 text-center">
        <img
          v-lazy="getAssetsFile('img/home/qr-code.png')"
          style="height: 150px; width: 150px;"
        >
        <div class="text-sm text-grey-9 mt-5">
          微信客服 Anna
        </div>
      </div>
    </div>
  </ti-content-row>
</template>

<script setup lang="ts">
import { getAssetsFile } from '@/utils/assets'
import { handledPathPush } from '@/utils/router'
const links = [
  {
    title: '钛灵AI市场',
    path: 'https://market.tiorb.com/'
  },
  {
    title: '钛灵AIX',
    path: 'https://www.tiorb.com/aix'
  },
  {
    title: '引力互联商城',
    path: 'https://store.gravitylink.com/'
  }
]
</script>
