import { RouteRecordRaw, RouterView } from 'vue-router'

const routes: RouteRecordRaw = {
  path: 'join',
  component: RouterView,
  meta: {
    title: '加入我们'
  },
  children: [
    {
      path: '',
      name: 'join',
      component: () => import('@/views/join/join-index.vue')
    },
    {
      path: 'detail/:id',
      name: 'join-detail',
      component: () => import('@/views/join/join-detail.vue'),
      meta: {
        title: '职位详情'
      }
    }
  ]
}

export default routes
