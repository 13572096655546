import NotFoundLayout from '@/layout/not-found-layout.vue'
import { RouteRecordRaw, RouterView } from 'vue-router'

const routes: RouteRecordRaw = {
  path: 'devices',
  component: RouterView,
  meta: {
    title: '智能硬件'
  },
  children: [
    {
      path: '',
      component: NotFoundLayout
    },
    {
      path: 'album',
      name: 'devices-album',
      component: () => import('@/views/devices/album/devices-album.vue'),
      meta: {
        title: '亲影相册智慧屏'
      }
    },
    {
      path: 'aix',
      name: 'devices-aix',
      component: () => import('@/views/devices/aix/devices-aix.vue'),
      meta: {
        title: '钛灵AIX超脑'
      }
    }
  ]
}

export default routes
