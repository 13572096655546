<template>
  <svg
    :class="svgClass"
    aria-hidden="true"
    v-on="$attrs"
    :style="{color: props?.color??'#547df9'}"
  >
    <use :xlink:href="iconName" />
  </svg>
</template>

<script setup lang="ts">
/**
   * 使用方式：
   * 在 template 中使用 <svg-icon name="bug"/>
   */
import { computed } from 'vue'
const props = defineProps< {name: string, color?: string}>()
const iconName = computed((): string => `#icon-${props.name}`)
const svgClass = computed((): string => {
  if (props.name) {
    return `svg-icon icon-${props.name}`
  } else {
    return 'svg-icon'
  }
})
</script>

<style scoped>
.svg-icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
</style>
