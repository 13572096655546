<template>
  <div ref="markdown" />
</template>

<script setup lang="ts">
import { marked } from 'marked'
import { onUpdated, ref } from 'vue'
const markdown = ref<HTMLElement>()
const props = defineProps<{markdown: string}>()

onUpdated(() => {
  if (markdown.value && props.markdown) {
    const data = marked(props.markdown)
    const shadow = markdown.value.shadowRoot ?? markdown.value.attachShadow({ mode: 'open' })
    if (shadow) {
      shadow.innerHTML = `
<style>
  *{
    color: #999999;
    line-height: 2rem;
  }
  strong, h1, h2, h3{
    color: #333333;
  }
  img{
  width: 100%;
  }
</style>
${data}
      `
    }
  }
})
</script>
