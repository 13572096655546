/**
 * AI算法请求
 */

import request from '@/utils/request'
import { AlgorithmScene } from '../types/algorithm'
import { Production, ProductionDetail, ProductionAdvantage } from '../types/production'

const base = 'algorithms/'

const algorithmAPI = {
  getDetail: (title: string) => {
    return request<ProductionDetail>(title + '/detail', base)
  },
  getProductions: (title: string) => {
    return request<Production[]>(title + '/productions', base)
  },
  getScenes: (title: string) => {
    return request<AlgorithmScene[]>(title + '/scenes', base)
  },
  getAdvantages: (title: string) => {
    return request<ProductionAdvantage[]>(title + '/advantages', base)
  },
  getRecommendations: (title: string) => {
    return request<Production[]>(title + '/recommendations', base)
  }
}

export default algorithmAPI
