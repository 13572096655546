<template>
  <ti-content-row>
    <ti-markdown-view
      class="min-h-screen my-14"
      :markdown="markdown"
      :key="route.params.doc"
    />
  </ti-content-row>
</template>

<script setup lang="ts">
import api from '@/api'
import { changeDocumentTitle } from '@/utils/router'
import { onMounted, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
const route = useRoute()
const router = useRouter()

const markdown = ref<string>('')
const handleDeclarationData = async () => {
  switch (route.params.doc) {
    case 'disclaimers':
      changeDocumentTitle('免责声明')
      break
    case 'privacy-policy':
      changeDocumentTitle('隐私政策')
      break
    default:
      changeDocumentTitle()
  }
  markdown.value = await api.mdAPI.getDeclarationMarkDown(route.params.doc as string) ?? ''
}
onMounted(async () => {
  handleDeclarationData()
})

watch(router.currentRoute, () => {
  handleDeclarationData()
})
</script>
