<template>
  <iframe
    width="100%"
    class="mt-14 overflow-hidden"
    :src="src"
    :height="iframeHeights.get(path as string)??4120"
    scrolling="no"
  />
</template>

<script setup lang="ts">
import 'element-plus/es/components/message/style/css'
import { ElMessage } from 'element-plus'
import { useRoute } from 'vue-router'
import { changeDocumentTitle } from '@/utils/router'
const route = useRoute()
const path = route.params.path
const src = `${import.meta.env.DEV ? 'http://home.tiorb.com/ai/' : '/ai/'}#/${path ?? ''}`
const iframeHeights = new Map<string, number>([
  ['bgSegment', 2530],
  ['faceDetect', 3220],
  ['sceneDetect', 3107],
  ['gestureDetect', 3377],
  ['faceFeatsDetect', 3040],
  ['faceClusterDetect', 2507],
  ['maskDetect', 3348],
  ['faceSearch', 3370],
  ['faceCompare', 3220],
  ['skyBgSegment', 3220]
])
const iframeTitles = new Map<string, string>([
  ['bgSegment', '背景分割'],
  ['faceDetect', '人脸检测'],
  ['sceneDetect', '场景分类'],
  ['gestureDetect', '手势识别'],
  ['faceFeatsDetect', '人脸关键点'],
  ['faceClusterDetect', '人脸聚类'],
  ['maskDetect', '人脸口罩检测'],
  ['faceSearch', '人脸搜索'],
  ['faceCompare', '人脸对比'],
  ['skyBgSegment', '天空替换']
])
const title = iframeTitles.get(path as string)
changeDocumentTitle(title)
window.addEventListener('message', (e) => {
  const data = e?.data
  if (data?.message) {
    ElMessage({ ...e.data, offset: 60 })
  }
})
</script>
